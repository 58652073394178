/**
 *
 * PendingApprovalPage
 *
 */

import * as React from 'react';

import { ContainerState, RootState } from './types';
import { Dispatch, compose } from 'redux';
import {
  approveOrders as approveOrdersActions,
  declineOrders as declineOrdersActions,
  editOrder as editOrderActions,
  orders as ordersActions,
} from './actions';

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import OrderFilter from './OrderFilter';
import OrderTable from './OrderTable';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectPendingApprovalPage from './selectors';
import { selectUserPermissions } from '../MainLayout/selectors';
import translations from 'translations';

interface OwnProps {
  pendingapprovalpage: ContainerState;
  permissions: string[];
}

interface StateProps {}

interface DispatchProps {
  applyFilter: (data) => void;
  approveOrders: (data) => void;
  declineOrders: (data) => void;
  updateOrder: (data) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class PendingApprovalPage extends React.PureComponent<Props> {
  public render() {
    const { orders, filter } = this.props.pendingapprovalpage;

    return (
      <div>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {/* <PageHeader>
          <FormattedMessage {...messages.header} />
        </PageHeader> */}
        <OrderFilter filter={filter} onSearch={this.props.applyFilter} />
        {orders.data.length || orders.loading ? (
          <Spin spinning={orders.loading}>
            <OrderTable
              orders={orders.data}
              loading={orders.loading}
              onApproveOrders={this.props.approveOrders}
              onDeclineOrders={this.props.declineOrders}
              onUpdateOrder={this.props.updateOrder}
            />
          </Spin>
        ) : (
          <p style={{ padding: '40pt', textAlign: 'center' }}>
            <FormattedMessage {...messages.hasNoOrders} />
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  pendingapprovalpage: selectPendingApprovalPage(),
  permissions: selectUserPermissions(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    applyFilter: (data) => dispatch(ordersActions.request(data)),
    approveOrders: (data) => dispatch(approveOrdersActions.request(data)),
    declineOrders: (data) => dispatch(declineOrdersActions.request(data)),
    updateOrder: (data) => dispatch(editOrderActions.request(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({ key: 'pendingApprovalPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'pendingApprovalPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(PendingApprovalPage);
