import { Checkbox as ACheckbox } from 'antd';
import styled from 'styled-components';

export default styled(ACheckbox)<any>`
  ${({ size, checked }) => {
    let style = ``;
    if (size === 'small') {
      style += `
        font-size: 14px !important;
        .ant-checkbox-inner {
          width: 12px;
          height: 12px;
          :after {
            left: 2.57142857px;
          }
        }
      `;
    }
    if (checked) {
      style += `
        color: rgba(0, 0, 0, 0.87);
      `;
    } else {
      style += `
        color: rgba(0, 0, 0, 0.54);
      `;
    }
    return style;
  }};
`;
