import React from 'react';
import { DatePicker as ADatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import messages from 'utils/messages';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import Calendar from './Calendar.svg';

const DatePicker = styled(ADatePicker)<any>`
  min-width: 100pt;
  ${media.sm`
    min-width: 140pt;
  `};
`;
const ARangePicker = ADatePicker.RangePicker;
const AMonthPicker = ADatePicker.MonthPicker;
const RangePicker = styled(ARangePicker)<any>`
  min-width: 100pt;
  ${media.sm`
    min-width: 140pt;
  `};
  .ant-calendar-range-picker-separator {
    vertical-align: unset;
  }
`;
const MonthPicker = styled(AMonthPicker)<any>`
  min-width: 80pt;
  ${media.sm`
    min-width: 140pt;
  `} .ant-calendar-range-picker-separator {
    vertical-align: unset;
  }
`;

const CalendarIcon = styled(SVGIcon)`
  svg {
    width: 15px;
  }
  path {
    fill: #2e904e;
  }
`;
const dateFormat = ['DD/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY', 'D/MM/YYYY'];
const monthFormat = 'MM/YYYY';

export default class TrackingCalendar extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      durationUserFocused: 0,
    };
  }

  public trackOnFocus = (event) => {
    const { onFocus } = this.props;
    if (typeof onFocus === 'function') {
      onFocus(event);
    }

    this.setState({
      durationUserFocused: window.performance.now(),
    });
  };

  public trackOnChangeRange = ([startDate, endDate]) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange([startDate, endDate]);
    }
  };

  public trackOnChangeMonth = (value) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  public trackOnChangeDate = (value) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function' && value) {
      onChange(value);
    }
  };

  public render() {
    const { trackingCategory, trackingAction, onFocus, onChange, ...props } = this.props;

    return props.range ? (
      <FormattedMessage {...messages.startDate}>
        {(startDate: string) => (
          <FormattedMessage {...messages.endDate}>
            {(endDate: string) => (
              <RangePicker
                format={dateFormat}
                showToday={false}
                placeholder={[startDate, endDate]}
                {...props}
                suffixIcon={<CalendarIcon renderAsImg={false} src={Calendar} />}
                onFocus={this.trackOnFocus}
                onChange={this.trackOnChangeRange}
              />
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
    ) : props.month ? (
      <MonthPicker
        format={monthFormat}
        showToday={false}
        {...props}
        suffixIcon={<CalendarIcon src={Calendar} />}
        onFocus={this.trackOnFocus}
        onChange={this.trackOnChangeMonth}
      />
    ) : (
      <DatePicker
        format={dateFormat}
        showToday={false}
        {...props}
        suffixIcon={<CalendarIcon src={Calendar} />}
        onFocus={this.trackOnFocus}
        onChange={this.trackOnChangeDate}
      />
    );
  }
}
