import * as qs from 'utils/queryString';

import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { approveOrder, declineOrder, getOrders } from 'utils/apollo';
import {
  approveOrders as approveOrdersActions,
  declineOrders as declineOrdersActions,
  orders as ordersActions,
} from './actions';
import { selectCountPendingOrder, selectSuppliers, makeSelectLocation } from 'containers/MainLayout/selectors';
import { selectFilter, selectOrders } from './selectors';

import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';
import { getStoreWithRetry } from 'containers/MainLayout/saga';
import { message } from 'antd';
import messages from './messages';
import { push } from 'connected-react-router';
import { recentOrders as recentOrdersMainLayout } from 'containers/MainLayout/actions';
import translations from 'translations';

const isEmptyObject = obj => !obj || !Object.keys(obj).length;

function computeSearchRequest({ filter, pagination }, storeId) {
  const { supplierName, orderDateRange, deliveryDateRange, ...rest } = filter;
  const { page = 0, size = 24 } = pagination || {};

  const requestParam = {
    filter: {
      storeId: storeId,
      statuses: ['PENDING_APPROVAL'],
      ...rest,
    },
    pagination: {
      page: page,
      size: size,
    },
  };

  if (!isEmptyObject(orderDateRange)) {
    requestParam.filter.orderDateRange = orderDateRange;
  }

  if (!isEmptyObject(deliveryDateRange)) {
    requestParam.filter.deliveryDateRange = deliveryDateRange;
  }

  return requestParam;
}

function* loadPendingApprovalOrdersFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.FETCH_ORDERS_REQUEST);
    const store = yield call(getStoreWithRetry);
    if (!store) {
      continue;
    }
    const location = yield select(makeSelectLocation());
    const oldQueryString = location.search.length > 0 ? location.search.slice(1) : '';
    const orders = yield select(selectOrders());
    const newQueryString = qs.stringify(payload);
    if (payload.filter.supplierName) {
      const { supplierNoFilter } = yield select(selectSuppliers());
      const supplier = supplierNoFilter.find((s: any) => s.name === payload.filter.supplierName);
      if (supplier) {
        payload.filter.supplierId = supplier.id;
      }
    }
    let response;
    if (oldQueryString === newQueryString) {
      if (orders.loading) {
        response = yield call(getOrders, computeSearchRequest(payload, store.id));
        if (!response.errors) {
          yield put(ordersActions.success(response));
        } else {
          yield put(ordersActions.failure(response.errors));
        }
      }
    } else {
      yield put(
        push({
          search: newQueryString,
        }),
      );
      response = yield call(getOrders, computeSearchRequest(payload, store.id));
      if (!response.errors) {
        yield put(ordersActions.success(response));
      } else {
        yield put(ordersActions.failure(response.errors));
      }
    }
  }
}

function* approvalOrdersFlow() {
  while (true) {
    const { payload: orderIds } = yield take(ActionTypes.APPROVE_ORDERS_REQUEST);

    const response = yield all(orderIds.map(orderId => call(approveOrder, { orderId: orderId })));
    if (!response[0].errors) {
      const countPending = yield select(selectCountPendingOrder());
      yield put(recentOrdersMainLayout.success({ countPendingOrders: countPending - response.length }));
      yield put(approveOrdersActions.success(response));
      message.success(translations(messages.approveSuccessfully));
    } else {
      yield put(approveOrdersActions.failure(response.errors));
      message.error(response[0].errors[0].message);
    }
  }
}

function* declineOrdersFlow() {
  const { payload: orderIds } = yield take(ActionTypes.DECLINE_ORDERS_REQUEST);

  const response = yield all(orderIds.map(orderId => call(declineOrder, { orderId: orderId })));

  if (!response[0].errors) {
    const countPending = yield select(selectCountPendingOrder());
    yield put(recentOrdersMainLayout.success({ countPendingOrders: countPending - response.length }));
    yield put(declineOrdersActions.success(response));
    yield call(refreshOrders);
  } else {
    yield put(declineOrdersActions.failure(response.errors));
    message.error(translations(messages.declineFailed));
  }
}

function* refreshOrders() {
  const location = yield select(makeSelectLocation());
  const filter = yield select(selectFilter());
  const parsedSearch = qs.parse(location.search);
  yield put(ordersActions.request(isEmptyObject(parsedSearch) ? { filter: filter } : parsedSearch));
}

function* editOrderFlow() {
  // const { payload } = yield take(ActionTypes.EDIT_ORDER_REQUEST);
  // const response;
  // if (!response.errors) {
  //   yield put(editOrderActions.success(response));
  //   yield call(refreshOrders);
  // } else {
  //   yield put(editOrderActions.failure(response.errors));
  // }
}

function* initData() {
  const { supplierNoFilter } = yield select(selectSuppliers());
  if (!supplierNoFilter.length) {
    yield take(MainActionTypes.FETCH_ALL_SUPPLIER_SUCCESS);
  }

  yield call(refreshOrders);
}

// Individual exports for testing
export default function* pendingApprovalPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(loadPendingApprovalOrdersFlow);
  yield fork(approvalOrdersFlow);
  yield fork(declineOrdersFlow);
  yield fork(editOrderFlow);

  yield fork(initData);
}
