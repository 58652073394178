import { Col, Radio, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import Calendar from 'components/TrackingComponents/Calendar/Calendar';
import { FormattedMessage } from 'react-intl';
import OrderFilterWrapper from './OrderFilterWrapper';
import { Viewport } from 'components/ViewportProvider';
import { debounce } from 'lodash';
import media from 'utils/mediaStyle';
import messages from './messages';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Container = styled.div`
  ${media.md`
    margin-bottom: 18pt;
  `};
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 10pt;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }
`;

const FilterActions = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 6pt 0;

  ${Button} {
    min-width: 100pt;
  }
  ${Button} + ${Button} {
    margin-left: 10pt;
  }
  ${media.md`
    ${Button} {
      width: auto;
    }
    flex-direction: row;
    ${Button} + ${Button} {
      margin-left: 20px;
    }
  `};
`;

interface OwnProps {
  filter: any;
  onSearch: (data) => void;
}

const OrderFilter = (props: OwnProps) => {
  const [selectedDate, setSelectedDate] = useState<any>(props.filter.orderDateRange ? 'orderDate' : 'deliveryDate');
  const [dateRange, setDateRange] = useState(props.filter.orderDateRange || props.filter.deliveryDateRange);

  const debouncedSearchOrder = useCallback(
    debounce((filter: any) => {
      props.onSearch({
        filter: {
          [filter.selectedDate === 'orderDate' ? 'orderDateRange' : 'deliveryDateRange']: filter.dateRange &&
            filter.dateRange.start &&
            filter.dateRange.end && {
              start: dayjs(filter.dateRange.start).toISOString(),
              end: dayjs(filter.dateRange.end).toISOString(),
            },
        },
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    setSelectedDate(props.filter.orderDateRange ? 'orderDate' : 'deliveryDate');
  }, [props.filter]);

  const onChangeRangeDate = ([start, end], isMobile?: boolean) => {
    setDateRange({ start: start, end: end });
    return (
      !isMobile &&
      debouncedSearchOrder({
        selectedDate: selectedDate,
        dateRange: { start: start, end: end },
      })
    );
  };

  const clearFilter = () => {
    setSelectedDate(props.filter.orderDateRange ? 'orderDate' : 'deliveryDate');
    setDateRange(props.filter.orderDateRange || props.filter.deliveryDateRange);
  };

  const filterMobile = () => {
    debouncedSearchOrder({
      selectedDate: selectedDate,
      dateRange: dateRange,
    });
  };

  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobile = width < 992;
        return (
          <OrderFilterWrapper>
            <Container>
              <Row gutter={20} align="bottom">
                <Col span={isMobile ? 24 : 10}>
                  <Label>
                    <Radio.Group
                      name="dateSelect"
                      defaultValue={selectedDate}
                      onChange={(value) => setSelectedDate(value)}
                    >
                      <Radio value="orderDate">
                        <FormattedMessage {...messages.orderDate} />
                      </Radio>
                      <Radio value="deliveryDate">
                        <FormattedMessage {...messages.deliveryDate} />
                      </Radio>
                    </Radio.Group>
                  </Label>
                  <Calendar
                    style={{ width: '100%' }}
                    trackingCategory="Pending Orders Page | DatePicker"
                    trackingAction="Search Order Date"
                    value={[
                      dateRange && dateRange.start && dayjs(dateRange.start),
                      dateRange && dateRange.end && dayjs(dateRange.end),
                    ]}
                    range
                    onChange={(value) => onChangeRangeDate(value, isMobile)}
                    size="large"
                  />
                </Col>
                {isMobile && (
                  <>
                    <FilterActions>
                      <Button type="secondary" onClick={clearFilter}>
                        <FormattedMessage {...messages.clear} />
                      </Button>
                      <Button type="primary" onClick={filterMobile}>
                        <FormattedMessage {...messages.apply} />
                      </Button>
                    </FilterActions>
                  </>
                )}
              </Row>
            </Container>
          </OrderFilterWrapper>
        );
      }}
    </Viewport.Consumer>
  );
};

export default OrderFilter;
