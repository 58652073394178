/*
 * PendingApprovalPage Messages
 *
 * This contains all the text for the PendingApprovalPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PendingApprovalPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đang chờ duyệt',
  },
  hasNoOrders: {
    id: `${scope}.hasNoOrders`,
    defaultMessage: 'Không có đơn hàng nào',
  },
  selectSupplier: {
    id: `${scope}.selectSupplier`,
    defaultMessage: 'Chọn nhà cung cấp',
  },
  orderDate: {
    id: `${scope}.orderDate`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: 'Ngày giao hàng',
  },
  orderTime: {
    id: `${scope}.orderTime`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryTime: {
    id: `${scope}.deliveryTime`,
    defaultMessage: 'Ngày giao hàng',
  },
  confirmDeclineOrders: {
    id: `${scope}.confirmDeclineOrders`,
    defaultMessage: 'Bạn có chắc muốn tự chối những đơn hàng đã chọn?',
  },
  declineReason: {
    id: `${scope}.declineReason`,
    defaultMessage: 'Lý do từ chối',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Tổng',
  },
  deliveryToday: {
    id: `${scope}.deliveryToday`,
    defaultMessage: 'Giao hàng hôm nay',
  },
  ordersToday: {
    id: `${scope}.ordersToday`,
    defaultMessage: 'Đơn hàng hôm nay',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'Nhà cung cấp',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Sản phẩm',
  },
  by: {
    id: `${scope}.by`,
    defaultMessage: 'Người mua',
  },
  approveSuccessfully: {
    id: `${scope}.approveSuccessfully`,
    defaultMessage: 'Duyệt đơn hàng thành công',
  },
  approveFailed: {
    id: `${scope}.approveFailed`,
    defaultMessage: 'Duyệt đơn hàng thất bại',
  },
  declineSuccessfully: {
    id: `${scope}.declineSuccessfully`,
    defaultMessage: 'Không duyệt đơn hàng thành công',
  },
  declineFailed: {
    id: `${scope}.declineFailed`,
    defaultMessage: 'Không duyệt đơn hàng thất bại',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Xoá',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Đồng ý',
  },
});
