/**
 *
 * UserTable
 *
 */

import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { InputNumber, Table } from 'antd';

import { Item } from './types';
import formatMoney from 'utils/formatMoney';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';

// import styled from 'styles/styled-components';

interface OwnProps {
  items: Item[];
  editedItems: {};
  onSetQuantity: (data) => void;
  onDeleteItem: (data) => void;
}

const Container = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  .ant-table tbody tr {
    cursor: pointer;
  }
  .ant-table-thead > tr > th {
    background: #f6ffed;
  }
  tbody {
    background-color: white;
  }
`;

const Text = styled.span`
  font-weight: 300;
`;

class OrderItemTable extends React.PureComponent<OwnProps> {
  public columns: any[];

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: translations(utilsMessages.itemName),
        key: 'product',
        dataIndex: 'product',
        render: (product) => <Text>{product.name}</Text>,
        width: 460,
      },
      {
        title: translations(utilsMessages.unitPrice),
        key: 'product',
        dataIndex: 'product',
        render: (product) => (
          <Text>
            {formatMoney(product.price)}/{product.uom}
          </Text>
        ),
      },
      {
        title: translations(utilsMessages.quantity),
        key: 'quantity',
        render: (item) => (
          <InputNumber
            size="small"
            value={props.editedItems[item.product.id] || item.orderedQuantity}
            onChange={(value) => props.onSetQuantity({ quantity: value, itemId: item.product.id })}
          />
        ),
      },
      {
        title: '',
        key: 'deleteItem',
        render: (item) => <DeleteOutlined onClick={() => props.onDeleteItem(item.product.id)} />,
        width: 60,
      },
    ];
  }

  public render() {
    return (
      <Container>
        <Table dataSource={this.props.items} columns={this.columns} pagination={false} />
      </Container>
    );
  }
}

export default OrderItemTable;
