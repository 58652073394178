/*
 *
 * PendingApprovalPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/PendingApprovalPage/DEFAULT_ACTION',
  FETCH_ORDERS_REQUEST = 'app/PendingApprovalPage/FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS = 'app/PendingApprovalPage/FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE = 'app/PendingApprovalPage/FETCH_ORDERS_FAILURE',
  APPROVE_ORDERS_REQUEST = 'app/PendingApprovalPage/APPROVE_ORDERS_REQUEST',
  APPROVE_ORDERS_SUCCESS = 'app/PendingApprovalPage/APPROVE_ORDERS_SUCCESS',
  APPROVE_ORDERS_FAILURE = 'app/PendingApprovalPage/APPROVE_ORDERS_FAILURE',
  DECLINE_ORDERS_REQUEST = 'app/PendingApprovalPage/DECLINE_ORDERS_REQUEST',
  DECLINE_ORDERS_SUCCESS = 'app/PendingApprovalPage/DECLINE_ORDERS_SUCCESS',
  DECLINE_ORDERS_FAILURE = 'app/PendingApprovalPage/DECLINE_ORDERS_FAILURE',
  EDIT_ORDER_REQUEST = 'app/PendingApprovalPage/EDIT_ORDER_REQUEST',
  EDIT_ORDER_SUCCESS = 'app/PendingApprovalPage/EDIT_ORDER_SUCCESS',
  EDIT_ORDER_FAILURE = 'app/PendingApprovalPage/EDIT_ORDER_FAILURE',
}

export default ActionTypes;
