/*
 *
 * PendingApprovalPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const orders = createAsyncAction(
  ActionTypes.FETCH_ORDERS_REQUEST,
  ActionTypes.FETCH_ORDERS_SUCCESS,
  ActionTypes.FETCH_ORDERS_FAILURE,
)<{}, {}, Error>();

export const approveOrders = createAsyncAction(
  ActionTypes.APPROVE_ORDERS_REQUEST,
  ActionTypes.APPROVE_ORDERS_SUCCESS,
  ActionTypes.APPROVE_ORDERS_FAILURE,
)<[], {}, Error>();

export const declineOrders = createAsyncAction(
  ActionTypes.DECLINE_ORDERS_REQUEST,
  ActionTypes.DECLINE_ORDERS_SUCCESS,
  ActionTypes.DECLINE_ORDERS_FAILURE,
)<[], {}, Error>();

export const editOrder = createAsyncAction(
  ActionTypes.EDIT_ORDER_REQUEST,
  ActionTypes.EDIT_ORDER_SUCCESS,
  ActionTypes.EDIT_ORDER_FAILURE,
)<{}, {}, Error>();
