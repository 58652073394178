import { Modal as AModal, Row, Spin } from 'antd';
import React, { useState } from 'react';

import Button from 'components/TrackingComponents/Button';
import CardLink from 'components/CardLink/CardLink';
import Checkbox from 'components/Checkbox/Checkbox';
import Edit from './Edit.svg';
import Ellipse from './Ellipse.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import { Order } from './types';
import OrderItemTable from './OrderItemTable';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import { Viewport } from 'components/ViewportProvider';
import formatMoney from 'utils/formatMoney';
import media from 'utils/mediaStyle';
import messages from './messages';
import moment from 'moment';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';

export const OrderBody = ({ children, style }: { children: JSX.Element | JSX.Element[]; style: any }) => (
  <div style={style}>{children}</div>
);

interface CellProps {
  header: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  flex?: string;
  link?: boolean;
}

const CellArrow = styled(SVGIcon)<any>`
  width: 26px;
  height: 26px;
`;

const CellLabel = styled.div`
  margin-bottom: 6pt;
  color: rgba(0, 0, 0, 0.54);
`;

const styleDiv = styled.div``;

const CellValue = styled(styleDiv)<any>`
  ${media.md`
    padding-right: 10px;
    white-space: nowrap;

    &.truncate {
      max-width: 15vw;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `};
`;

const CellWrapper = styled.div<any>`
  flex: ${({ flex }) => flex || '1'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.md`
    flex-direction: column;
  `};
`;

export const Cell = ({ header, content, flex, link }: CellProps) => (
  <CellWrapper flex={flex}>
    <CellLabel>{header}</CellLabel>
    {link ? (
      <CellValue className="truncate" title={content}>
        <Link to={`/suppliers/${encodeURIComponent(content as string)}`}>{content}</Link>
      </CellValue>
    ) : (
      <CellValue className="no-wrap">{content}</CellValue>
    )}
  </CellWrapper>
);

const OrderRowWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
    align-items: center;
  `};
  background-color: #fcfffc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  margin-bottom: 5pt;
  padding: 6pt;
  cursor: pointer;

  :hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f0f8fb;
    ${CellArrow} path {
      fill: #fff;
    }
    ${CellArrow} circle {
      fill: #2e904e;
    }
  }
`;

const Actions = styled.div`
  button {
    margin-left: 16pt;
  }
`;

const OrderRow = ({
  order,
  selected,
  onToggleSelected,
  onApproveOrder,
  onEditOrder,
}: {
  order: Order;
  selected: boolean;
  onToggleSelected: (orderId) => void;
  onApproveOrder: () => void;
  onEditOrder: () => void;
}) => (
  <Viewport.Consumer>
    {({ width }) => {
      const isMobileSize = width < 768;
      return (
        <CardLink hasShadow to={`/orders/${order.id}`}>
          <OrderRowWrapper status={order.status}>
            <>
              {!isMobileSize && (
                <div>
                  <div style={{ marginRight: 8 }}>
                    <Checkbox checked={selected} onChange={() => onToggleSelected(order.id)} />
                  </div>
                </div>
              )}
              <Cell
                header={<FormattedMessage {...messages.orderTime} />}
                content={`${moment(order.orderDate).format('HH:mm | DD/MM/YYYY')}`}
              />
              <Cell
                header={<FormattedMessage {...messages.deliveryTime} />}
                content={`${moment(order.deliveryTime.start).format('HH:mm')} - ${moment(order.deliveryTime.end).format(
                  'HH:mm | DD/MM/YYYY',
                )}`}
              />
              <Cell header={<FormattedMessage {...messages.to} />} content={order.supplier.name} link />
              <Cell header={<FormattedMessage {...messages.by} />} content={order.creator.name} />
              <Cell header={<FormattedMessage {...messages.total} />} content={`${formatMoney(+order.total)}đ`} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {isMobileSize && (
                  <Checkbox checked={selected} onChange={() => onToggleSelected(order.id)} style={{ paddingTop: 8 }} />
                )}
                <Button
                  onClick={onApproveOrder}
                  size="small"
                  type="primary"
                  style={{ marginRight: isMobileSize ? 0 : 16 }}
                >
                  <FormattedMessage {...utilsMessages.approve} />
                </Button>
              </div>
              <div className="hideOnSmall">
                <CellArrow src={Ellipse} wrapper="span" className="hideOnMobile" />
              </div>
            </>
          </OrderRowWrapper>
        </CardLink>
      );
    }}
  </Viewport.Consumer>
);

const TopActionsWrapper = styled.div<any>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(4, 15, 15, 0.12);
  z-index: 2;

  button {
    padding: 0 10px;
    margin-left: 10px;
  }

  ${media.md`
    position: static;
    padding: 0;
    background-color: transparent;
    margin-bottom: 16px;
    box-shadow: unset;
  `};

  @media (min-width: 380px) {
    button {
      margin-left: 16px;
      padding: 0 20px;
    }
  }
`;

export default ({
  orders,
  loading,
  onApproveOrders,
  onDeclineOrders,
  onUpdateOrder,
}: {
  orders: Order[];
  loading: boolean;
  onApproveOrders: (data) => void;
  onDeclineOrders: (data) => void;
  onUpdateOrder: (data) => void;
}) => {
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [editedOrderItems, setEditedOrderItems] = useState({});
  const [deletedOrderItems, setDeletedOrderItems] = useState({});
  const onToggleSelectedOrder = (orderId) => {
    if (selectedOrders.indexOf(orderId) === -1) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders([...selectedOrders.filter((oId) => oId !== orderId)]);
    }
  };
  const onSelectedAllOrders = () => {
    if (selectedOrders.length === orders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders.map((o) => o.id));
    }
  };
  const onApproveOrdersHandle = () => {
    if (selectedOrders.length) {
      onApproveOrders(selectedOrders);
    }
  };
  const onDeclineOrdersHandle = () => {
    if (selectedOrders.length) {
      AModal.confirm({
        autoFocusButton: null,
        okButtonProps: {
          danger: true,
        },
        title: translations(messages.confirmDeclineOrders),
        okText: translations(utilsMessages.yes),
        cancelText: translations(utilsMessages.no),
        onOk: () => {
          onDeclineOrders(selectedOrders);
        },
      });
    }
  };
  const onSetItemQuantity = (data: { itemId: string; quantity: number }) => {
    editedOrderItems[data.itemId] = data.quantity;
    setEditedOrderItems({ ...editedOrderItems });
  };
  const onDeleteItem = (productId) => {
    deletedOrderItems[productId] = true;
    setDeletedOrderItems({ ...deletedOrderItems });
  };
  const onSubmitSaveQuantity = () => {
    onUpdateOrder({
      orderId: selectedOrder && selectedOrder.id,
      editedOrderItems: editedOrderItems,
      deletedOrderItems: deletedOrderItems,
    });
    onCloseEditModal();
  };
  const onCloseEditModal = () => {
    setEditedOrderItems({});
    setSelectedOrder(undefined);
  };
  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobile = width < 1025;
        return (
          <Spin spinning={loading}>
            <TopActionsWrapper>
              <Row justify="space-between" align="middle">
                <div style={{ marginLeft: isMobile ? 'unset' : 8 }}>
                  <Checkbox
                    checked={selectedOrders.length === orders.length}
                    indeterminate={!!selectedOrders.length && selectedOrders.length < orders.length}
                    onChange={onSelectedAllOrders}
                  >
                    <FormattedMessage {...utilsMessages.selectAll} />
                  </Checkbox>
                </div>
                <Actions>
                  <Button onClick={onDeclineOrdersHandle}>
                    <FormattedMessage {...utilsMessages.decline} />
                  </Button>
                  <Button type="primary" onClick={onApproveOrdersHandle}>
                    <FormattedMessage {...utilsMessages.approve} />
                  </Button>
                </Actions>
              </Row>
            </TopActionsWrapper>
            <OrderBody style={{ marginBottom: isMobile ? 70 : 'unset' }}>
              {orders.map((order, index) => (
                <OrderRow
                  key={index}
                  order={order}
                  selected={selectedOrders.indexOf(order.id) > -1}
                  onToggleSelected={onToggleSelectedOrder}
                  onApproveOrder={() => onApproveOrders([order.id])}
                  onEditOrder={() => setSelectedOrder(order)}
                />
              ))}
            </OrderBody>
            <Modal
              size="large"
              headerLabel={selectedOrder && selectedOrder.supplier.name}
              headerIcon={Edit}
              isOpen={!!selectedOrder}
              onClose={onCloseEditModal}
              onSubmit={onSubmitSaveQuantity}
              closeLabel={<FormattedMessage {...utilsMessages.cancel} />}
              submitLabel={<FormattedMessage {...utilsMessages.save} />}
            >
              <OrderItemTable
                items={(selectedOrder ? selectedOrder.items : []).filter((item) => !deletedOrderItems[item.product.id])}
                editedItems={editedOrderItems}
                onSetQuantity={onSetItemQuantity}
                onDeleteItem={onDeleteItem}
              />
            </Modal>
          </Spin>
        );
      }}
    </Viewport.Consumer>
  );
};
