/*
 *
 * PendingApprovalPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  orders: {
    loading: true,
    data: [],
  },
  filter: {
    supplierName: '',
    orderDateRange: null,
    deliveryDateRange: null,
  },
  pagination: {
    page: 0,
    size: 48,
  },
};

function pendingApprovalPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload.filter,
        },
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    case ActionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...payload,
          loading: false,
        },
      };
    case ActionTypes.APPROVE_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          data: state.orders.data.filter(order => payload.findIndex(item => item.order.id === order.id) === -1),
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default pendingApprovalPageReducer;
