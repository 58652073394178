import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the pendingApprovalPage state domain
 */

const selectPendingApprovalPageDomain = (state: ApplicationRootState) => {
  return state ? state.pendingApprovalPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by PendingApprovalPage
 */

const selectOrders = () =>
  createSelector(selectPendingApprovalPageDomain, substate => {
    return substate.orders;
  });

const selectFilter = () =>
  createSelector(selectPendingApprovalPageDomain, substate => {
    return substate.filter;
  });

const selectPendingApprovalPage = () =>
  createSelector(selectPendingApprovalPageDomain, substate => {
    return substate;
  });

export default selectPendingApprovalPage;
export { selectPendingApprovalPageDomain, selectOrders, selectFilter };
