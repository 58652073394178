import React, { FC } from 'react';
import Button from 'components/Button/Button';
import { ButtonProps } from 'antd/lib/button';

export type ButtonType = 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | 'link' | 'secondary' | 'bg-primary';
interface TrackingButtonProps extends Omit<ButtonProps, 'type'> {
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  trackingAction?: string;
  trackingCategory?: string;
}
const TrackingButton: FC<TrackingButtonProps> = (props) => {
  const { onClick, trackingAction, trackingCategory, children, ...rest } = props;
  const trackOnClick: React.MouseEventHandler<HTMLElement> = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <Button {...rest} onClick={trackOnClick}>
      {children}
    </Button>
  );
};

export default TrackingButton;
